import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Transaction from './components/Transaction';
import Weaver from './components/Weaver';
import SareeDesign from './components/SareeDesign';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './authContext';
import RequireAuth from './RequireAuth';
import axios from 'axios';
import Settings from './components/settings';

axios.defaults.withCredentials = true;

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/home" element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          } />
          <Route path="/transaction" element={
            <RequireAuth>
              <Transaction />
            </RequireAuth>
          } />
          <Route path="/weaver" element={
            <RequireAuth>
              <Weaver />
            </RequireAuth>
          } />
          <Route path="/sareedesign" element={
            <RequireAuth>
              <SareeDesign />
            </RequireAuth>
          } />
          <Route path="/settings" element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          } />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
