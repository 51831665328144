import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../authContext';
import logo from '../assets/logo.png';
import userIcon from '../assets/usersIcon.svg';
import { BASE_URL } from '../config/constant';
import { useNavigate } from 'react-router-dom';

function Settings() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { handleLogout } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ username: '', password: '' });
    const [password, setPassword] = useState('');
    const [activeSection, setActiveSection] = useState('changePassword'); // For section toggling
    const navigate = useNavigate();
    const [message, setMessage] = useState({ type: '', text: '' });

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/userInfo`, { withCredentials: true });
                setUsername(response.data.username);
            } catch (error) {
                console.error('Error fetching user info:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };
        fetchUserInfo();
    }, [navigate]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/user`, { withCredentials: true });
                setUsers(response.data.filter(user => user.username !== 'admin'));
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchUsers();
    }, []);

    const updatePassword = async () => {
        try {
            await axios.put(`${BASE_URL}/updatePassword`, { password }, { withCredentials: true });
            setMessage({ type: 'success', text: 'Password updated successfully!' });
            setPassword('');
        } catch (error) {
            console.error('Error updating password:', error);
            setMessage({ type: 'error', text: 'Error updating password. Please try again.' });
        }
    };

    const addUser = async () => {
        if (username === 'admin' || username === 'nrs') {
            try {
                await axios.post(`${BASE_URL}/addUser`, newUser, { withCredentials: true });
                setMessage({ type: 'success', text: 'User added successfully!' });
                setNewUser({ username: '', password: '' });
            } catch (error) {
                console.error('Error adding user:', error);
                setMessage({ type: 'error', text: 'Error adding user. Please try again.' });
            }
        } else {
            setMessage({ type: 'error', text: 'You do not have permission to add a user.' });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.logOut')) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='home_m'>
            <div className="w100 headerContainer">
                <div className="p-0">
                    <div className="header">
                        <div className="logoSec">
                            <div className="logo">
                                <img src={logo} alt="Logo" />
                            </div>
                        </div>
                        <div className="munuContainer">
                            <div className="menuLists">
                                <div className="navigation-buttons">
                                    <a href="/home" className="menuBtn navigation-button">Home</a>
                                    <a href="/transaction" className="menuBtn navigation-button">Transaction</a>
                                    <a href="/weaver" className="menuBtn navigation-button">Weaver</a>
                                    <a href="/sareedesign" className="menuBtn navigation-button">Saree Design</a>
                                    <a href="/settings" className="menuBtn navigation-button active">Settings</a>
                                </div>
                            </div>
                        </div>
                        <div className='logOut' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <p className='userName'>{username}</p>
                            <div>
                                <img src={userIcon} className='img-fluids' alt="User Icon" />
                                <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                                    <button onClick={() => handleLogout()} className="dropdown-item">Logout</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-5 text-center p-3">
                <button className={`btn locBtn m-2 ${activeSection === 'changePassword' ? 'active' : ''}`} onClick={() => setActiveSection('changePassword')}>Change Password</button>
                {username === 'admin' || username === 'nrs' ? (
                    <>
                        <button className={`btn locBtn m-2 ${activeSection === 'addUser' ? 'active' : ''}`} onClick={() => setActiveSection('addUser')}>Add User</button>
                        <button className={`btn locBtn m-2 ${activeSection === 'viewUsers' ? 'active' : ''}`} onClick={() => setActiveSection('viewUsers')}>View Users</button>
                    </>
                ) : null}
            </div>
            <div className="w50">
                <div className="settingsCard p-3">
                    {message.text && (
                        <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                            {message.text}
                        </div>
                    )}

                    {activeSection === 'changePassword' && (
                        <form onSubmit={(e) => { e.preventDefault(); updatePassword(); }}>
                            <div className="title text-center mb-2"><h3>Change Password</h3></div>
                            <input
                                type="password"
                                className="inputControl w100"
                                placeholder="New Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button type="submit" className="btn submitBtn w100 mt-2">Update Password</button>
                        </form>
                    )}

                    {activeSection === 'addUser' && (
                        <div>
                            <div className="title text-center mb-2"><h3>Add User</h3></div>
                            <input
                                type="text"
                                className="inputControl w100"
                                placeholder="Username"
                                value={newUser.username}
                                onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                            />
                            <input
                                type="password"
                                className="inputControl w100 mt-2"
                                placeholder="Password"
                                value={newUser.password}
                                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                            />
                            <button className="btn submitBtn w100 mt-2" onClick={addUser}>Add User</button>
                        </div>
                    )}

                    {activeSection === 'viewUsers' && (
                        <div>
                            <div className="title text-center mb-2"><h3>All Users</h3></div>
                            <table className="table table-striped table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Created Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user) => (
                                        <tr key={user.id}>
                                            <td>{user.username}</td>
                                            <td>{user.created_at}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Settings;
