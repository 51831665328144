import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import { AuthContext } from '../authContext';
import logo from '../assets/logo.png';
import userIcon from '../assets/usersIcon.svg';
import sareeImage from '../assets/homePageSarees.png';
import { BASE_URL } from '../config/constant';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import { format } from 'date-fns';

function Home() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { handleLogout } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [transactions, setTransactions] = useState([]);
    const generatePastMonths = (yearsBack) => {
        const months = [];
        const now = new Date();
        for (let i = 0; i < yearsBack * 12; i++) {
            const date = new Date();
            date.setMonth(now.getMonth() - i);
            const formattedDate = format(date, 'yyyy-MM');
            if (date <= now) {
                months.push(formattedDate);
            }
        }
        return months;
    };

    const months = generatePastMonths(2);

    const [month, setMonth] = useState(months[0]); 
    const navigate = useNavigate();

    Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/userInfo`, { withCredentials: true });
                setUsername(response.data.username);
            } catch (error) {
                console.error('Error fetching user info:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            }
        };
        fetchUserInfo();
    }, [navigate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (!event.target.closest('.logOut')) {
            setIsDropdownOpen(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/location`);
                const sortedLocations = response.data.locations.sort();
                setLocations(sortedLocations);
                if (sortedLocations.length > 0) {
                    setSelectedLocation(sortedLocations[0]);
                }
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        };
        fetchLocations();
    }, []);

    useEffect(() => {
        if (!selectedLocation) return;

        console.log('Fetching transactions for:', selectedLocation, month);
        const fetchTransactions = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/transaction/${selectedLocation}`, {
                    params: { month },
                });

                console.log('API Response:', response.data);
                setTransactions(response.data.transactions);
            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };
        fetchTransactions();
    }, [selectedLocation, month]);


    // const daysInMonth = new Date(month + '-01').getDate();
    const chartData = {
    labels: transactions.map(t => t.day),  // Assuming 'day' is a field in each transaction
    datasets: [
        {
            label: 'Income',
            data: transactions.map(t => parseFloat(t.income)),
            fill: false,
            borderColor: 'rgba(84, 148, 56, 0.7)',  // Green color with some transparency
            backgroundColor: 'rgba(84, 148, 56, 0.3)',  // Transparent green for point background
            borderWidth: 2,  // Thicker line for visibility
            tension: 0.1,
            pointStyle: 'circle',  // Different point style for income
            pointRadius: 3,  // Increase point size for better visibility
        },
        {
            label: 'Expense',
            data: transactions.map(t => parseFloat(t.expense)),
            fill: false,
            borderColor: 'rgba(255, 0, 54, 0.7)',  // Red color with some transparency
            backgroundColor: 'rgba(255, 0, 54, 0.3)',  // Transparent red for point background
            borderWidth: 2,  // Thicker line for visibility
            tension: 0.1,
            pointStyle: 'rectRot',  // Different point style for expense
            pointRadius: 3,  // Increase point size for better visibility
            borderDash: [5, 5],  // Dashed line for distinction
        },
    ],
};


    // console.log('Chart Data:', chartData);
    // console.log('Transactions:', transactions);
    // console.log('Transactions for the month:', transactions);
    // console.log('Days in Month:', daysInMonth); 
    return (
        <div className='home_m'>
            <div className="w100 headerContainer">
                <div className="p-0">
                    <div className="header">
                        <div className="logoSec">
                            <div className="logo">
                                <img src={logo} alt="Logo" />
                            </div>
                        </div>
                        <div className="munuContainer">
                            <div className="menuLists">
                                <div className="navigation-buttons">
                                    <a href="/home" className="menuBtn navigation-button active">Home</a>
                                    <a href="/transaction" className="menuBtn navigation-button">Transaction</a>
                                    <a href="/weaver" className="menuBtn navigation-button">Weaver</a>
                                    <a href="/sareedesign" className="menuBtn navigation-button">Saree Design</a>
                                    <a href="settings" className="menuBtn navigation-button">Settings</a>
                                </div>
                                {/* <div className="logOut"> */}
                                <div className='logOut' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                    <p className='userName'>{username}</p>
                                    <div>
                                        <img src={userIcon} className='img-fluids' alt="User Icon" />
                                        <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                                            <button onClick={() => handleLogout()} className="dropdown-item">Logout</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row"><div className='col-md-3'>
                    <div className="text-center my-4 saree-design-preview">
                        <img src={sareeImage}
                            alt="Featured Saree Design" className="featured-image" />
                    </div>
                </div>
                    <div className="col-md-9 px-4 homeDesign my-4">
                        <div className="filter my-4 ">
                            <div className='filterRow'>
                                <label htmlFor="location-select" className='fw-bold'>Location:</label>
                                <select
                                    id="location-select" className='form-controls '
                                    value={selectedLocation}
                                    onChange={(e) => setSelectedLocation(e.target.value)}
                                >
                                    {locations.map((location) => (
                                        <option key={location} value={location}>
                                            {location}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='filterRow'>
                                <label htmlFor="month-select" className='fw-bold'>Month:</label>
                                <select
                                    id="month-select" className='form-controls custom-month-select'
                                    value={month}
                                    onChange={(e) => setMonth(e.target.value)}
                                >
                                    {months.map((monthOption) => (
                                        <option key={monthOption} value={monthOption}>
                                            {monthOption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 chart-container">
                            <Line data={chartData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
