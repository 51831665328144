import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BASE_URL } from '../config/constant';
import './SareeDesign.css';
import logo from '.././assets/logo.png';
import userIcon from '.././assets/usersIcon.svg';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../authContext';
import uploadFiles from '.././assets/uploadFile.svg';
import upload from '.././assets/upload.svg';
import download from '.././assets/download.svg';
import deletefile from '.././assets/delete.svg';
import cancelBtn from '../assets/cancelBtn.svg';

function SareeDesign() {
  const [loomNames, setLoomNames] = useState([]);
  const [loomOptions, setLoomOptions] = useState([]);
  const [designNames, setDesignNames] = useState([]);
  const [filteredDesigns, setFilteredDesigns] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { handleLogout } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [filterLoomName, setFilterLoomName] = useState('');  // For filtering loom names
  const [filterDesignName, setFilterDesignName] = useState('');  // For filtering design names
  const mainImageRef = useRef(null);
  const munthiImageRef = useRef(null);
  const blouseImageRef = useRef(null);
  const openImageRef = useRef(null);
  const colorSetImageRef = useRef(null);
  const navigate = useNavigate();

  // const handleReplace = (designId, field, file) => {
  // Handle file upload logic here
  // After the file is uploaded, update the state to reflect the change
  //   setUploadedFiles((prevState) => ({
  //     ...prevState,
  //     [field]: true, 
  //   }));
  // };


  // Fetch user info on component mount
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/userInfo`, {
          withCredentials: true,
        });
        setUsername(response.data.username);  // Set username from response
      } catch (error) {
        console.error('Error fetching user info:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');  // Redirect to login if unauthorized
        }
      }
    };
    fetchUserInfo();
  }, [navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.logOut')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchLoomNames();
    fetchDesignNames();
    fetchFilteredDesigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUniqueLoomNames = (looms) => {
    const loomNames = looms.map((loom) => loom.loomName);
    return [...new Set(loomNames)];
  };

  // Fetch loom names and apply the unique filter
  const fetchLoomNames = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/looms`);
      setLoomNames(getUniqueLoomNames(response.data));  // Set unique loom names
    } catch (error) {
      console.error('Error fetching loom names:', error);
      setErrorMessage('Error fetching loom names. Please try again later.');
    }
  };


  const fetchDesignNames = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/design-names`);

      // Extract unique design names using a Set
      const uniqueDesignNames = Array.from(new Set(response.data.map(design => design.designName)));

      // Update the state with unique design names
      setDesignNames(uniqueDesignNames);
    } catch (error) {
      console.error('Error fetching design names:', error);
      setErrorMessage('Error fetching design names. Please try again later.');
    }
  };

  // const fetchRecentDesigns = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/recent-saree-designs`);
  //     setFilteredDesigns(response.data);
  //   } catch (error) {
  //     console.error('Error fetching recent designs:', error);
  //     setErrorMessage('Error fetching recent designs. Please try again later.');
  //   }
  // };

  const fetchFilteredDesigns = async (loomName, designName) => {
    try {
      const queryParams = new URLSearchParams();
      if (loomName) queryParams.append('loomName', loomName);
      if (designName) queryParams.append('designName', designName);

      const response = await axios.get(`${BASE_URL}/recent-saree-designs?${queryParams.toString()}`);
      setFilteredDesigns(response.data);
    } catch (error) {
      console.error('Error fetching filtered designs:', error);
      setErrorMessage('Error fetching filtered designs. Please try again later.');
    }
  };

  const fetchLoomNumbers = async (loomName) => {
    try {
      const response = await axios.get(`${BASE_URL}/loom-numbers/${loomName}`);  // Ensure the correct endpoint is used

      if (response.data.length > 0) {
        const maxLoomNumber = response.data[0].loomNumber;
        setLoomOptions(Array.from({ length: maxLoomNumber }, (_, i) => i + 1));  // Set loom numbers as options
      } else {
        setLoomOptions([]);  // No loom numbers found, clear options
      }
    } catch (error) {
      console.error('Error fetching loom numbers:', error);
      setErrorMessage('Error fetching loom numbers. Please try again later.');
    }
  };

  const handleFormLoomNameChange = (event) => {
    const selectedLoomName = event.target.value;
    formik.setFieldValue('loomName', selectedLoomName);  // Set loomName in the form
    fetchLoomNumbers(selectedLoomName);  // Fetch loom numbers based on selected loom name in form
  };

  const handleFilterLoomNameChange = (event) => {
    const selectedLoomName = event.target.value;
    setFilterLoomName(selectedLoomName);
    fetchFilteredDesigns(selectedLoomName, filterDesignName);
  };

  const formik = useFormik({
    initialValues: {
      loomName: '',
      loomNumber: '',
      designName: '',
      mainImage: null,
      munthiImage: null,
      blouseImage: null,
      openImage: null,
      colorSetImage: null,
    },
    validationSchema: Yup.object({
      loomName: Yup.string().required('Loom name is required'),
      loomNumber: Yup.string().required('Loom number is required'),
      designName: Yup.string().required('Design name is required'),
      mainImage: Yup.mixed().required('Main image is required')
        .test('fileSize', 'File is too large', value => !value || value.size <= 5242880)
        .test('fileType', 'Unsupported file format', value => !value || ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)),
      munthiImage: Yup.mixed().nullable().test('fileSize', 'File is too large', value => !value || value.size <= 5242880)
        .test('fileType', 'Unsupported file format', value => !value || ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)),
      blouseImage: Yup.mixed().nullable().test('fileSize', 'File is too large', value => !value || value.size <= 5242880)
        .test('fileType', 'Unsupported file format', value => !value || ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)),
      openImage: Yup.mixed().nullable().test('fileSize', 'File is too large', value => !value || value.size <= 5242880)
        .test('fileType', 'Unsupported file format', value => !value || ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)),
      colorSetImage: Yup.mixed().nullable().test('fileSize', 'File is too large', value => !value || value.size <= 5242880)
        .test('fileType', 'Unsupported file format', value => !value || ['image/jpeg', 'image/png', 'image/jpg'].includes(value.type)),
    }),
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append('loomName', values.loomName);
      formData.append('loomNumber', values.loomNumber);
      formData.append('designName', values.designName);
      formData.append('mainImage', values.mainImage);
      if (values.munthiImage) formData.append('munthiImage', values.munthiImage);
      if (values.blouseImage) formData.append('blouseImage', values.blouseImage);
      if (values.openImage) formData.append('openImage', values.openImage);
      if (values.colorSetImage) formData.append('colorSetImage', values.colorSetImage);

      try {
        await axios.post(`${BASE_URL}/saree-designs`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        resetForm();
        mainImageRef.current.value = "";
        munthiImageRef.current.value = "";
        blouseImageRef.current.value = "";
        openImageRef.current.value = "";
        colorSetImageRef.current.value = "";
        fetchFilteredDesigns();
        setErrorMessage('');
      } catch (error) {
        console.error('Error submitting saree design:', error);
        setErrorMessage("An error occurred while submitting saree design. Please try again later.");
      }
    },
  });

  const handleFormDesignNameChange = (event) => {
    formik.setFieldValue('designName', event.target.value);
  };


  const handleFilterDesignNameChange = (event) => {
    const selectedDesignName = event.target.value;
    setFilterDesignName(selectedDesignName);
    fetchFilteredDesigns(filterLoomName, selectedDesignName);
  };

  const handleClearFilters = () => {
    setFilterLoomName('');
    setFilterDesignName('');
    fetchFilteredDesigns();
  };

  const viewDesignDetails = (design) => {
    setSelectedDesign(design);
  };

  document.addEventListener('DOMContentLoaded', function () {
    const fileInput = document.querySelector('input[type="file"]');
    const iconWrapper = document.querySelector('.icon-wrappers1');

    if (fileInput && iconWrapper) {
      fileInput.addEventListener('change', function () {
        if (this.files.length > 0) {
          iconWrapper.classList.add('uploaded');
        }
      });
    }
  });


  const handleReplace = async (id, field, file) => {
    const formData = new FormData();
    formData.append('field', field);
    formData.append(field, file);

    try {
      const response = await axios.put(`${BASE_URL}/saree-designs/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const updatedImageUrl = response.data.updatedImageUrl;

      setFilteredDesigns((prevDesigns) =>
        prevDesigns.map(design =>
          design.id === id ? { ...design, [field]: updatedImageUrl } : design
        )
      );

      setSelectedDesign((prevDesign) =>
        prevDesign ? { ...prevDesign, [field]: updatedImageUrl } : prevDesign
      );

      setErrorMessage('');
    } catch (error) {
      console.error('Error replacing image:', error);
      setErrorMessage("An error occurred while replacing the image. Please try again later.");
    }
  };

  // useEffect(() => {
  //   console.log("Loom Names: ", loomNames);
  //   console.log("Design Names: ", designNames);
  // }, [loomNames, designNames]);

  const handleDelete = async (id, field) => {
    try {
      await axios.delete(`${BASE_URL}/saree-designs/${id}/${field}`);

      setFilteredDesigns((prevDesigns) =>
        prevDesigns.map(design =>
          design.id === id ? { ...design, [field]: null } : design
        )
      );

      setSelectedDesign((prevDesign) =>
        prevDesign ? { ...prevDesign, [field]: null } : prevDesign
      );

      setErrorMessage('');
    } catch (error) {
      console.error('Error deleting image:', error);
      setErrorMessage("An error occurred while deleting the image. Please try again later.");
    }
  };
  useEffect(() => {
    if (selectedDesign) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto'; // Cleanup on unmount
    };
  }, [selectedDesign]);

  const renderImages = (design) => (
    <>
      <div className="design-images">
        {['mainImage', 'munthiImage', 'blouseImage', 'openImage', 'colorSetImage'].map((field, idx) => (
          <div key={idx} className="design-cards">
            <div className="image-container">
              {design[field] ? (
                <img src={design[field]} alt={field} className="img-fluids" />
              ) : (
                <div className="placeholder-image">No Image</div>
              )}
            </div>
            <div className="icon-container">
              <label htmlFor={`upload-${field}-${idx}`} className="icon-wrappers1 uploaded">
                <img src={upload} alt="Upload" className="action-icon" />
              </label>
              <input
                type="file"
                id={`upload-${field}-${idx}`}
                className="file-input"
                onChange={(e) => handleReplace(design.id, field, e.target.files[0])}
              />
              <button className="icon-wrappers2" onClick={() => handleDelete(design.id, field)}>
                <img src={deletefile} alt="Delete" className="action-icon" />
              </button>
              {design[field] ? (
                <a href={design[field]} download className="icon-wrappers3">
                  <img src={download} alt="Download" className="action-icon" />
                </a>
              ) : (
                <button disabled className="icon-wrappers3">
                  <img src={download} alt="Download" className="action-icon" />
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="design-buttons text-center">
        {/* <button className="btn btn-secondary" onClick={() => setSelectedDesign(null)}>Back</button> */}
      </div>
    </>
  );

  return (
    <div className="row w-100">
      <div className="w100 headerContainer">
        <div className="p-0">
          <div className="header">
            <div className="logoSec">
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
            </div>
            <div className="munuContainer">
              <div className="menuLists">
                <div className="navigation-buttons">
                  <a href="/home" className="menuBtn navigation-button">Home</a>
                  <a href="/transaction" className="menuBtn navigation-button">Transaction</a>
                  <a href="/weaver" className="menuBtn navigation-button">Weaver</a>
                  <a href="/sareedesign" className="menuBtn navigation-button active">Saree Design</a>
                  <a href="/settings" className="menuBtn navigation-button">Settings</a>
                </div>
                {/* <div className="logOut"> */}
                <div className='logOut' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                  <p className='userName'>{username}</p>
                  <div>
                    <img src={userIcon} className='img-fluids' alt="User Icon" />
                    <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                      <button onClick={() => handleLogout()} className="dropdown-item">Logout</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="add-saree my-5">
          <h2 className="text-center mt-3 addSaree">Add Saree Design</h2>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group mt-4 fw-bold">
              <label>Select Loom Name:</label>
              <select
                className="form-control"
                value={formik.values.loomName}
                onChange={handleFormLoomNameChange}  // Handle form loom name changes
              >
                <option value="">Select Loom Name</option>
                {loomNames.map((loom) => (
                  <option key={loom} value={loom}>
                    {loom}
                  </option>
                ))}
              </select>
              {formik.touched.loomName && formik.errors.loomName ? <div className="invalid-feedback">{formik.errors.loomName}</div> : null}
            </div>

            <div className="form-group fw-bold">
              <label>Select Loom Number:</label>
              <select
                className="form-control"
                value={formik.values.loomNumber}
                onChange={formik.handleChange}  // Handle form loom number changes
                name="loomNumber"
              >
                <option value="">Select Loom Number</option>
                {loomOptions.map((number) => (
                  <option key={number} value={number}>
                    {number}  {/* Display loom numbers correctly */}
                  </option>
                ))}
              </select>
              {formik.touched.loomNumber && formik.errors.loomNumber ? <div className="invalid-feedback">{formik.errors.loomNumber}</div> : null}
            </div>

            <div className="form-group fw-bold">
              <label>Select Design Name:</label>
              <select
                className="form-control"
                value={formik.values.designName}
                onChange={handleFormDesignNameChange}
              >
                <option value="">Select Design Name</option>
                {designNames.map(designName => (
                  <option key={designName} value={designName}>
                    {designName} {/* Display designName as a string */}
                  </option>
                ))}
              </select>
              {formik.touched.designName && formik.errors.designName ? <div className="invalid-feedback">{formik.errors.designName}</div> : null}
            </div>
            <div className="form-group custom-upload-wrappers">
              <div className="upload-container">
                <label className="custom-upload">
                  <img src={uploadFiles} alt="Upload File" className="upload-icon" />
                  <span className="upload-text">Upload Main</span>
                  <input
                    type="file"
                    className={`form-control-file hidden-file-input ${formik.touched.mainImage && formik.errors.mainImage ? 'is-invalid' : ''}`}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      formik.setFieldValue('mainImage', file);
                      formik.setFieldValue('mainFileName', file ? file.name : '');
                    }}
                    onBlur={formik.handleBlur}
                    name="mainImage"
                    ref={mainImageRef}
                    multiple={false}
                  />
                </label>
                {formik.values.mainFileName && (
                  <span className="file-names">{formik.values.mainFileName}</span>
                )}
                {formik.touched.mainImage && formik.errors.mainImage ? (
                  <div className="invalid-feedback">{formik.errors.mainImage}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group custom-upload-wrappers">
              <div className="upload-container">
                <label className="custom-upload">
                  <img src={uploadFiles} alt="Upload File" className="upload-icon" />
                  <span className="upload-text">Upload Munthi</span>
                  <input
                    type="file"
                    className={`form-control-file hidden-file-input ${formik.touched.munthiImage && formik.errors.munthiImage ? 'is-invalid' : ''}`}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      formik.setFieldValue('munthiImage', file);
                      formik.setFieldValue('munthiFileName', file ? file.name : '');
                    }}
                    onBlur={formik.handleBlur}
                    name="munthiImage"
                    ref={munthiImageRef}
                    multiple={false}
                  />
                </label>
                {formik.values.munthiFileName && (
                  <span className="file-names">{formik.values.munthiFileName}</span>
                )}
                {formik.touched.munthiImage && formik.errors.munthiImage ? (
                  <div className="invalid-feedback">{formik.errors.munthiImage}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group custom-upload-wrappers">
              <div className="upload-container">
                <label className="custom-upload">
                  <img src={uploadFiles} alt="Upload File" className="upload-icon" />
                  <span className="upload-text">Upload Blouse</span>
                  <input
                    type="file"
                    className={`form-control-file hidden-file-input ${formik.touched.blouseImage && formik.errors.blouseImage ? 'is-invalid' : ''}`}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      formik.setFieldValue('blouseImage', file);
                      formik.setFieldValue('blouseFileName', file ? file.name : '');
                    }}
                    onBlur={formik.handleBlur}
                    name="blouseImage"
                    ref={blouseImageRef}
                    multiple={false}
                  />
                </label>
                {formik.values.blouseFileName && (
                  <span className="file-names">{formik.values.blouseFileName}</span>
                )}
                {formik.touched.blouseImage && formik.errors.blouseImage ? (
                  <div className="invalid-feedback">{formik.errors.blouseImage}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group custom-upload-wrappers">
              <div className="upload-container">
                <label className="custom-upload">
                  <img src={uploadFiles} alt="Upload File" className="upload-icon" />
                  <span className="upload-text">Upload Open</span>
                  <input
                    type="file"
                    className={`form-control-file hidden-file-input ${formik.touched.openImage && formik.errors.openImage ? 'is-invalid' : ''}`}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      formik.setFieldValue('openImage', file);
                      formik.setFieldValue('openFileName', file ? file.name : '');
                    }}
                    onBlur={formik.handleBlur}
                    name="openImage"
                    ref={openImageRef}
                    multiple={false}
                  />
                </label>
                {formik.values.openFileName && (
                  <span className="file-names">{formik.values.openFileName}</span>
                )}
                {formik.touched.openImage && formik.errors.openImage ? (
                  <div className="invalid-feedback">{formik.errors.openImage}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group custom-upload-wrappers">
              <div className="upload-container">
                <label className="custom-upload">
                  <img src={uploadFiles} alt="Upload File" className="upload-icon" />
                  <span className="upload-text">Upload Color Set</span>
                  <input
                    type="file"
                    className={`form-control-file hidden-file-input ${formik.touched.colorSetImage && formik.errors.colorSetImage ? 'is-invalid' : ''}`}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      formik.setFieldValue('colorSetImage', file);
                      formik.setFieldValue('colorSetFileName', file ? file.name : '');
                    }}
                    onBlur={formik.handleBlur}
                    name="colorSetImage"
                    ref={colorSetImageRef}
                    multiple={false}
                  />
                </label>
                {formik.values.colorSetFileName && (
                  <span className="file-names">{formik.values.colorSetFileName}</span>
                )}
                {formik.touched.colorSetImage && formik.errors.colorSetImage ? (
                  <div className="invalid-feedback">{formik.errors.colorSetImage}</div>
                ) : null}
              </div>
            </div>
            <button type="submit" className="submitBtn w-100">Submit</button>
          </form>
        </div>
      </div>
      <div className="col-md-9">
        <h2 className="text-end mt-5 design-details-heading">Recent Designs</h2>
        <div className="view-sarees mt-4 mb-2">
          <div className="form-groups">
            <label className='fw-bold'>Loom Name:</label>
            <select
              className="form-control"
              value={filterLoomName}  // Use the filter loom name state
              onChange={handleFilterLoomNameChange}  // Call the handler to update filter
            >
              <option value="">Select Loom Name</option>
              {loomNames.map((loom) => (
                <option key={loom} value={loom}>
                  {loom}
                </option>
              ))}
            </select>
          </div>
          <div className="form-groups">
            <label className='fw-bold'>Design Name:</label>
            <select
              className="form-control"
              value={filterDesignName}  // Use the filter design name state
              onChange={handleFilterDesignNameChange}  // Call the handler to update filter
            >
              <option value="">Select Design Name</option>
              {designNames.map(designName => (
                <option key={designName} value={designName}>
                  {designName} {/* Display designName as a string */}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='button-container'>
          <button className="my-3 mb-4 btn-end" onClick={handleClearFilters}>Clear Filters</button></div>
        <div className="design-grid">
          {filteredDesigns.map((design) => (
            <div key={design.id} className="design-card" onClick={() => viewDesignDetails(design)}>
              <img src={design.mainImage} alt="Main" className="img-circle" />
              <div className='design-layout'>
                <div className="design-info">
                  <h5>{design.loomName} - {design.loomNumber}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        {selectedDesign && (
          <div className="modal-overlays">
            <div className="modal-contents" onClick={(e) => e.stopPropagation()}>
              <h2 className="design-details-heading">Design Details</h2>
              {renderImages(selectedDesign)}
              <button className="modal-closes" onClick={() => setSelectedDesign(null)}><img src={cancelBtn} alt='cancel' /></button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SareeDesign;
