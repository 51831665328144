import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BASE_URL } from '../config/constant';
import './Weaver.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import logo from '.././assets/logo.png';
import userIcon from '.././assets/usersIcon.svg';
import uploadFiles from '.././assets/uploadFile.svg';
import actionBtn from '.././assets/actionBtn.svg';
import downloadBtn from '.././assets/downloadBtn.svg';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../authContext';

library.add(faDownload);

const Weaver = () => {
    const [weavers, setWeavers] = useState([]);
    const [looms, setLooms] = useState([]);
    const [designs, setDesigns] = useState([]);
    const [loomTypes, setLoomTypes] = useState([]);
    const [jacquardTypes, setJacquardTypes] = useState([]);
    const [designNames, setDesignNames] = useState([]);
    const [loomNumbers, setLoomNumbers] = useState([]);
    const [filteredWeavers, setFilteredWeavers] = useState([]);
    const [filteredLooms, setFilteredLooms] = useState([]);
    const [filteredDesigns, setFilteredDesigns] = useState([]);
    const [activeTable, setActiveTable] = useState('weavers');
    const [formType, setFormType] = useState('weaver');
    const [formData, setFormData] = useState({
        weaverName: '',
        loomName: '',
        address: '',
        area: '',
        mobileNumber1: '',
        mobileNumber2: '',
        reference: '',
        description: '',
        idProof: null,
        loomNumber: '',
        loomType: '',
        jacquardType: '',
        hooks: '',
        planSheet: null,
        designName: '',
        designBy: '',
        designUpload: null,
        newLoomType: '',
        newJacquardType: '',
        newDesignName: ''
    });
    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [loomFilter, setLoomFilter] = useState('');
    const [loomNumberFilter, setLoomNumberFilter] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateId, setUpdateId] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { handleLogout } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/userInfo`, {
                    withCredentials: true,
                });
                setUsername(response.data.username);  // Set username from response
            } catch (error) {
                console.error('Error fetching user info:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');  // Redirect to login if unauthorized
                }
            }
        };
        fetchUserInfo();
    }, [navigate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.logOut')) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const handleTableChange = (table) => {
        setActiveTable(table);
    };

    useEffect(() => {
        let timer;
        if (error) {
            timer = setTimeout(() => {
                setError('');
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [error]);

    const fetchData = async () => {
        try {
            const weaverResponse = await axios.get(`${BASE_URL}/weavers`);
            const loomResponse = await axios.get(`${BASE_URL}/looms`);
            const designResponse = await axios.get(`${BASE_URL}/designs`);
            const loomTypesResponse = await axios.get(`${BASE_URL}/loomTypes`);
            const jacquardTypesResponse = await axios.get(`${BASE_URL}/jacquardTypes`);
            const designNamesResponse = await axios.get(`${BASE_URL}/designNames`);

            setWeavers(weaverResponse.data);
            setLooms(loomResponse.data);
            setDesigns(designResponse.data);
            setLoomTypes(loomTypesResponse.data);
            setJacquardTypes(jacquardTypesResponse.data);
            setDesignNames(designNamesResponse.data);
            setFilteredWeavers(weaverResponse.data);
            setFilteredLooms(loomResponse.data);
            setFilteredDesigns(designResponse.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'idProof') {
            setFormData(prevFormData => ({
                ...prevFormData,
                idProof: files[0]
            }));
        } else {
            setFormData({
                ...formData,
                [name]: files ? files[0] : value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) return;
        setSubmitting(true);
        try {
            if (isUpdating) {
                if (formType === 'weaver') {
                    await handleUpdateWeaverForm();
                } else if (formType === 'loom') {
                    await handleUpdateLoomForm();
                } else if (formType === 'design') {
                    await handleUpdateDesignForm();
                }
            } else {
                if (formType === 'weaver') {
                    await handleSubmitWeaverForm();
                } else if (formType === 'loom') {
                    await handleSubmitLoomForm();
                } else if (formType === 'design') {
                    await handleSubmitDesignForm();
                }
            }
            fetchData();
            resetForm();
            setError('');
        } catch (error) {
            setError(error.response.data.error);
        } finally {
            setSubmitting(false);
            setIsUpdating(false);
            setUpdateId(null);
        }
    };

    const handleSubmitWeaverForm = async () => {
        const formDataObj = new FormData();

        Object.keys(formData).forEach(key => {
            formDataObj.append(key, formData[key]);
        });
        console.log(formDataObj);
        await axios.post(`${BASE_URL}/weavers`, formDataObj);
    };

    const handleSubmitLoomForm = async () => {
        const formDataObj = new FormData();
        Object.keys(formData).forEach(key => {
            formDataObj.append(key, formData[key]);
        });
        await axios.post(`${BASE_URL}/looms`, formDataObj);
    };

    const handleSubmitDesignForm = async () => {
        const formDataObj = new FormData();
        Object.keys(formData).forEach(key => {
            formDataObj.append(key, formData[key]);
        });
        await axios.post(`${BASE_URL}/designs`, formDataObj);
    };

    const handleUpdateWeaverForm = async () => {
        const formDataObj = new FormData();
        Object.keys(formData).forEach(key => {
            formDataObj.append(key, formData[key]);
        });
        await axios.put(`${BASE_URL}/weavers/${updateId}`, formDataObj);
    };

    const handleUpdateLoomForm = async () => {
        const formDataObj = new FormData();
        Object.keys(formData).forEach(key => {
            formDataObj.append(key, formData[key]);
        });
        await axios.put(`${BASE_URL}/looms/${updateId}`, formDataObj);
    };

    const handleUpdateDesignForm = async () => {
        const formDataObj = new FormData();
        Object.keys(formData).forEach(key => {
            formDataObj.append(key, formData[key]);
        });
        await axios.put(`${BASE_URL}/designs/${updateId}`, formDataObj);
    };

    const handleLoomNameChange = (e) => {
        const loomName = e.target.value;
        setFormData({
            ...formData,
            loomName,
            loomNumber: ''
        });

        const loomsWithSameName = looms.filter((l) => l.loomName === loomName);
        if (loomsWithSameName.length > 0) {
            const maxLoomNumber = Math.max(...loomsWithSameName.map(l => l.loomNumber));
            setLoomNumbers(Array.from({ length: maxLoomNumber }, (_, i) => i + 1));
        } else {
            setLoomNumbers([]);
        }
    };

    // Function to extract unique loom names
    const getUniqueLoomNames = (looms) => {
        const loomNames = looms.map((loom) => loom.loomName);
        // Use Set to keep only unique loom names
        return [...new Set(loomNames)];
    };


    const handleUpdateClick = (item, type) => {
        setFormType(type);
        setIsUpdating(true);
        setUpdateId(item.id);
        handleLoomNameChange({ target: { value: item.loomName } });

        const planSheet = item.planSheet ? item.planSheet : null;
        const designUpload = item.designUpload ? item.designUpload : null;

        setFormData({
            ...item,
            idProof: item.idProof || null,
            planSheet,
            designUpload
        });
    };

    // const applyFilters = () => {
    //     let filteredWeavers = weavers;
    //     let filteredLooms = looms;
    //     let filteredDesigns = designs;
    //     if (loomFilter) {
    //         filteredWeavers = filteredWeavers.filter(weaver => weaver.loomName === loomFilter);
    //         filteredLooms = filteredLooms.filter(loom => loom.loomName === loomFilter);
    //         filteredDesigns = filteredDesigns.filter(design => design.loomName === loomFilter);
    //     }
    //     if (loomNumberFilter) {
    //         filteredDesigns = filteredDesigns.filter(design => design.loomNumber === parseInt(loomNumberFilter));
    //     }
    //     setFilteredWeavers(filteredWeavers);
    //     setFilteredLooms(filteredLooms);
    //     setFilteredDesigns(filteredDesigns);
    // };

    const resetForm = () => {
        setFormData({
            weaverName: '',
            loomName: '',
            address: '',
            area: '',
            mobileNumber1: '',
            mobileNumber2: '',
            reference: '',
            description: '',
            idProof: null,
            loomNumber: '',
            loomType: '',
            jacquardType: '',
            hooks: '',
            planSheet: null,
            designName: '',
            designBy: '',
            designUpload: null,
            newLoomType: '',
            newJacquardType: '',
            newDesignName: ''
        });
        const idProofInput = document.getElementById('idProof');
        if (idProofInput) idProofInput.value = null;

        const planSheetInput = document.getElementById('planSheet');
        if (planSheetInput) planSheetInput.value = null;

        const designUploadInput = document.getElementById('designUpload');
        if (designUploadInput) designUploadInput.value = null;
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            const nextElement = form.elements[index + 1];
            if (nextElement) {
                nextElement.focus();
            }
            e.preventDefault();
        }
    };

    // const downloadFile = (url, filename) => {
    //     axios.get(url, { responseType: 'blob' })
    //         .then((response) => {
    //             const url = window.URL.createObjectURL(new Blob([response.data]));
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download', filename);
    //             document.body.appendChild(link);
    //             link.click();
    //         })
    //         .catch((error) => console.error("Error downloading file:", error));
    // };

    return (
        <div className="con">
            <div className="w100 headerContainer">
                <div className="p-0">
                    <div className="header">
                        <div className="logoSec">
                            <div className="logo">
                                <img src={(logo)} alt="Logo" />
                            </div>
                        </div>
                        <div className="munuContainer">
                            <div className="menuLists">
                                <div className="navigation-buttons">
                                    <a href="/home" className="menuBtn navigation-button">Home</a>
                                    <a href="/transaction" className="menuBtn navigation-button ">Transaction</a>
                                    <a href="/weaver" className="menuBtn navigation-button active">Weaver</a>
                                    <a href="/sareedesign" className="menuBtn navigation-button">Saree Design</a>
                                    <a href="/settings" className="menuBtn navigation-button">Settings</a>
                                </div>
                                <div className='logOut' onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                                    <p className='userName'>{username}</p>
                                    <div>
                                        <img src={userIcon} className='img-fluids' alt="User Icon" />
                                        <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                                            <button onClick={() => handleLogout()} className="dropdown-item">Logout</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row w100">
                <div className="col-lg-3  mt-5 mb-3">
                    <div className="accordion weaverForm">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingWeaver">
                                <button
                                    className="accordion-button btn formBtn"
                                    type="button"
                                    onClick={() => { setFormType('weaver'); resetForm(); setIsUpdating(false); setError(null); }}
                                    aria-expanded={formType === 'weaver'}
                                    aria-controls="collapseWeaver"
                                >
                                    Add Weaver
                                </button>
                            </h2>
                            <div id="collapseWeaver" className={`accordion-collapse collapse ${formType === 'weaver' ? 'show' : ''}`}>
                                <div className="accordion-body">
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                                        <h2 className='text-center'>{isUpdating ? `Update Weaver` : `Add Weaver`}</h2>
                                        <div className="form-groups">
                                            <label htmlFor="weaverName">Weaver Name</label>
                                            <input type="text" className="form-control" id="weaverName" placeholder='Enter weaver name' name="weaverName" value={formData.weaverName} onChange={handleInputChange} required />
                                        </div>
                                        <div className="form-groups">
                                            <label htmlFor="loomName">Loom Name</label>
                                            <input type="text" className="form-control" id="loomName" name="loomName" placeholder='Enter loom name' value={formData.loomName} onChange={handleInputChange} required />
                                        </div>
                                        <div className="form-groups">
                                            <label htmlFor="address">Address</label>
                                            <input type="text" className="form-control" id="address" name="address" placeholder='Address' value={formData.address} onChange={handleInputChange} required />
                                        </div>
                                        <div className="form-groups">
                                            <label htmlFor="area">Area</label>
                                            <input type="text" className="form-control" id="area" name="area" value={formData.area} placeholder='Area' onChange={handleInputChange} required />
                                        </div>
                                        <div className="form-groups">
                                            <label htmlFor="mobileNumber1">Mobile Number 1</label>
                                            <input type="text" className="form-control" id="mobileNumber1" name="mobileNumber1" placeholder='Enter mobile number' value={formData.mobileNumber1} onChange={handleInputChange} required pattern="\d{10}" title="Please enter a valid 10-digit mobile number" />
                                        </div>
                                        <div className="form-groups">
                                            <label htmlFor="mobileNumber2">Mobile Number 2</label>
                                            <input type="text" className="form-control" id="mobileNumber2" name="mobileNumber2" placeholder='Enter mobile number' value={formData.mobileNumber2} onChange={handleInputChange} pattern="\d{10}" title="Please enter a valid 10-digit mobile number" />
                                        </div>
                                        <div className="form-groups">
                                            <label htmlFor="reference">Reference</label>
                                            <input type="text" className="form-control" id="reference" name="reference" placeholder='Enter reference' value={formData.reference} onChange={handleInputChange} required />
                                        </div>
                                        <div className="form-groups">
                                            <label htmlFor="description">Description</label>
                                            <textarea className="form-control" id="description" name="description" placeholder='Description' value={formData.description} onChange={handleInputChange} required></textarea>
                                        </div>
                                        <div className="form-group custom-upload-wrappers">
                                            <div className="upload-container">
                                                <label className="custom-upload">
                                                    <img src={uploadFiles} alt="Upload ID Proof" className="upload-icon" />
                                                    <span className="upload-text">ID Proof</span>
                                                    <input
                                                        type="file"
                                                        className={`form-control-file hidden-file-input ${!formData.idProof ? 'is-invalid' : ''}`}
                                                        id="idProof"
                                                        name="idProof"
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </label>
                                                {formData.idProof && (
                                                    <span className="file-name">{formData.idProof.name}</span>
                                                )}
                                            </div>
                                        </div>
                                        <button type="submit" className="btn submitBtn mt-3" disabled={submitting}>{isUpdating ? 'Update' : 'Add'} Weaver</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingLoom">
                                <button
                                    className="accordion-button btn formBtn my-2"
                                    type="button"
                                    onClick={() => { setFormType('loom'); resetForm(); setIsUpdating(false); setError(null); }}
                                    aria-expanded={formType === 'loom'}
                                    aria-controls="collapseLoom"
                                >
                                    Add Loom
                                </button>
                            </h2>
                            <div id="collapseLoom" className={`accordion-collapse collapse ${formType === 'loom' ? 'show' : ''}`}>
                                <div className="accordion-body">
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    {formType === 'loom' && (
                                        <form onSubmit={handleSubmit}>
                                            <h2 className='text-center'>{isUpdating ? `Update Loom` : `Add Loom`}</h2>
                                            <div className="form-groups">
                                                <label htmlFor="loomName">Loom Name</label>
                                                <select className="form-control" id="loomName" name="loomName" value={formData.loomName} onChange={handleLoomNameChange} required>
                                                    <option value="">Select Loom Name</option>
                                                    {weavers.map((weaver) => (
                                                        <option key={weaver.id} value={weaver.loomName}>{weaver.loomName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-groups">
                                                <label htmlFor="loomNumber">Loom Number</label>
                                                <input type="tel" className="form-control" id="loomNumber" name="loomNumber" placeholder="Enter new loom number" onChange={handleInputChange} required />
                                            </div>
                                            <div className="form-groups">
                                                <label htmlFor="loomType">Loom Type</label>
                                                <select className="form-control" id="loomType" name="loomType" value={formData.loomType} onChange={handleInputChange} required>
                                                    <option value="">Select Loom Type</option>
                                                    {loomTypes.map((type) => (
                                                        <option key={type} value={type}>{type}</option>
                                                    ))}
                                                    <option value="other">Other</option>
                                                </select>
                                                {formData.loomType === 'other' && (
                                                    <input type="text" className="form-control mt-2" id="newLoomType" name="newLoomType" placeholder="Enter new loom type" onChange={handleInputChange} required />
                                                )}
                                            </div>
                                            <div className="form-groups">
                                                <label htmlFor="jacquardType">Jacquard Type</label>
                                                <select className="form-control" id="jacquardType" name="jacquardType" value={formData.jacquardType} onChange={handleInputChange} required>
                                                    <option value="">Select Jacquard Type</option>
                                                    {jacquardTypes.map((type) => (
                                                        <option key={type} value={type}>{type}</option>
                                                    ))}
                                                    <option value="other">Other</option>
                                                </select>
                                                {formData.jacquardType === 'other' && (
                                                    <input type="text" className="form-control mt-2" id="newJacquardType" name="newJacquardType" placeholder="Enter new jacquard type" onChange={handleInputChange} required />
                                                )}
                                            </div>
                                            <div className="form-groups">
                                                <label htmlFor="hooks">Hooks</label>
                                                <input type="number" className="form-control" id="hooks" placeholder='Enter Hooks' name="hooks" value={formData.hooks} onChange={handleInputChange} required min="0" />
                                            </div>
                                            <div className="form-groups">
                                                <label htmlFor="description">Description</label>
                                                <textarea className="form-control" id="description" placeholder='Description' name="description" value={formData.description} onChange={handleInputChange} required></textarea>
                                            </div>
                                            <button type="submit" className="btn submitBtn mt-3" disabled={submitting}>{isUpdating ? 'Update' : 'Add'} Loom</button>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingDesign">
                                <button
                                    className="accordion-button btn formBtn"
                                    type="button"
                                    onClick={() => { setFormType('design'); resetForm(); setIsUpdating(false); setError(null); }}
                                    aria-expanded={formType === 'design'}
                                    aria-controls="collapseDesign"
                                >
                                    Add Design
                                </button>
                            </h2>
                            <div id="collapseDesign" className={`accordion-collapse collapse ${formType === 'design' ? 'show' : ''}`}>
                                <div className="accordion-body">
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    {formType === 'design' && (
                                        <form onSubmit={handleSubmit}>
                                            <h2 className='text-center'>{isUpdating ? `Update Design` : `Add Design`}</h2>
                                            <div className="form-groups">
                                                <label htmlFor="loomName">Loom Name</label>
                                                <select className="form-control" id="loomName" name="loomName" value={formData.loomName} onChange={handleLoomNameChange} required>
                                                    <option value="">Select Loom Name</option>
                                                    {getUniqueLoomNames(looms).map((loomName) => (
                                                        <option key={loomName} value={loomName}>{loomName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-groups">
                                                <label htmlFor="loomNumber">Loom Number</label>
                                                <select className="form-control" id="loomNumber" name="loomNumber" value={formData.loomNumber} onChange={handleInputChange} required>
                                                    <option value="">Select Loom Number</option>
                                                    {loomNumbers.map((number) => (
                                                        <option key={number} value={number}>{number}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-groups">
                                                <label htmlFor="designName">Design Name</label>
                                                <select className="form-control" id="designName" name="designName" value={formData.designName} onChange={handleInputChange} onKeyDown={handleKeyPress} required>
                                                    <option value="">Select Design Name</option>
                                                    {designNames.map((name) => (
                                                        <option key={name} value={name}>{name}</option>
                                                    ))}
                                                    <option value="other">Add New Design Name</option>
                                                </select>
                                                {formData.designName === 'other' && (
                                                    <input type="text" className="form-control mt-2" id="newDesignName" name="newDesignName" placeholder="Enter new design name" onChange={handleInputChange} onKeyDown={handleKeyPress} required />
                                                )}
                                            </div>
                                            <div className="form-groups">
                                                <label htmlFor="designBy">Design By</label>
                                                <input type="text" className="form-control" id="designBy" name="designBy" value={formData.designBy} onChange={handleInputChange} onKeyDown={handleKeyPress} required />
                                            </div>
                                            <div className="form-group custom-upload-wrappers">
                                                <div className="upload-container">
                                                    <label className="custom-upload">
                                                        <img src={uploadFiles} alt="Upload Plan Sheet" className="upload-icon" />
                                                        <span className="upload-text">Plan Sheet</span>
                                                        <input
                                                            type="file"
                                                            className={`form-control-file hidden-file-input ${!formData.planSheet ? 'is-invalid' : ''}`}
                                                            id="planSheet"
                                                            name="planSheet"
                                                            onChange={handleInputChange}
                                                            required={!formData.planSheet}
                                                        />
                                                    </label>
                                                    {formData.planSheet && (
                                                        <span className="file-name">{formData.planSheet.name}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group custom-upload-wrappers">
                                                <div className="upload-container">
                                                    <label className="custom-upload">
                                                        <img src={uploadFiles} alt="Upload Design" className="upload-icon" />
                                                        <span className="upload-text">Design</span>
                                                        <input
                                                            type="file"
                                                            className={`form-control-file hidden-file-input ${!formData.designUpload ? 'is-invalid' : ''}`}
                                                            id="designUpload"
                                                            name="designUpload"
                                                            onChange={handleInputChange}
                                                            required={!formData.designUpload}
                                                        />
                                                    </label>
                                                    {formData.designUpload && (
                                                        <span className="file-name">{formData.designUpload.name}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn submitBtn mt-3" disabled={submitting}>{isUpdating ? 'Update' : 'Add'} Design</button>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 recentWeaver px-4 mt-5 mb-3">
                    <div className='weaverFilters'>
                        <div className="form-groups">
                            <label htmlFor="loomFilter">Loom Name</label>
                            <select
                                className="form-control"
                                id="loomFilter"
                                value={loomFilter}
                                onChange={(e) => {
                                    const loomName = e.target.value;
                                    setLoomFilter(loomName);

                                    if (loomName) {
                                        const loomsWithSameName = looms.filter((loom) => loom.loomName === loomName);
                                        const loomNumbers = loomsWithSameName.map((loom) => loom.loomNumber);
                                        setLoomNumbers(loomNumbers);

                                        setFilteredWeavers(weavers.filter(weaver => weaver.loomName === loomName));
                                        setFilteredLooms(loomsWithSameName);
                                        setFilteredDesigns(designs.filter(design => design.loomName === loomName));
                                    } else {
                                        setFilteredWeavers(weavers);
                                        setFilteredLooms(looms);
                                        setFilteredDesigns(designs);
                                        setLoomNumbers([]);
                                    }
                                }}
                            >
                                <option value="">All</option>
                                {Array.from(new Set(looms.map((loom) => loom.loomName))).map((loomName) => (
                                    <option key={loomName} value={loomName}>{loomName}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-groups">
                            <label htmlFor="loomNumberFilter">Loom Number</label>
                            <select
                                className="form-control"
                                id="loomNumberFilter"
                                value={loomNumberFilter}
                                onChange={(e) => {
                                    const loomNumber = e.target.value;
                                    setLoomNumberFilter(loomNumber);

                                    if (loomNumber) {
                                        setFilteredDesigns(designs.filter(design =>
                                            design.loomName === loomFilter &&
                                            design.loomNumber === parseInt(loomNumber)
                                        ));
                                    } else {
                                        setFilteredDesigns(designs.filter(design => design.loomName === loomFilter));
                                    }
                                }}
                                disabled={!loomFilter}
                            >
                                <option value="">All</option>
                                {loomNumbers.map((number) => (
                                    <option key={number} value={number}>{number}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mt-3 weaverBtn">
                        <button
                            className={`btn locBtns ${activeTable === 'weavers' ? 'active' : ''}`}
                            onClick={() => handleTableChange('weavers')}
                        >
                            Weavers
                        </button>
                        <button
                            className={`btn locBtns ${activeTable === 'loom' ? 'active' : ''}`}
                            onClick={() => handleTableChange('loom')}
                        >
                            Loom
                        </button>
                        <button
                            className={`btn locBtns ${activeTable === 'design' ? 'active' : ''}`}
                            onClick={() => handleTableChange('design')}
                        >
                            Design
                        </button>
                    </div>
                    {activeTable === 'weavers' && (
                        <>
                            <h2 className='text-end'>Recent Weavers</h2>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Weaver Name</th>
                                        <th>Loom Name</th>
                                        <th>Address</th>
                                        <th>Area</th>
                                        <th>Mobile Number 1</th>
                                        <th>Mobile Number 2</th>
                                        <th>Reference</th>
                                        <th>Description</th>
                                        <th>ID Proof</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredWeavers.map((weaver) => (
                                        <tr key={weaver.id}>
                                            <td>{weaver.weaverName}</td>
                                            <td>{weaver.loomName}</td>
                                            <td>{weaver.address}</td>
                                            <td>{weaver.area}</td>
                                            <td>{weaver.mobileNumber1}</td>
                                            <td>{weaver.mobileNumber2 || 'No Number'}</td>
                                            <td>{weaver.reference}</td>
                                            <td>{weaver.description}</td>
                                            <td>
                                                <a href={weaver.idProof} target="_blank" rel="noopener noreferrer" className="download-link">
                                                    <img src={downloadBtn} alt='download' className='img-fluid' />
                                                </a>
                                            </td>
                                            <td>
                                                <button className="btn actionBtn" onClick={() => handleUpdateClick(weaver, 'weaver')}><img src={actionBtn} className='actionBtnIcon' alt="Update Icon" />Update</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}

                    {activeTable === 'loom' && (
                        <>
                            <h2 className='text-end'>Recent Looms</h2>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Loom Name</th>
                                        <th>Loom Number</th>
                                        <th>Loom Type</th>
                                        <th>Jacquard Type</th>
                                        <th>Hooks</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredLooms.map((loom) => (
                                        <tr key={loom.id}>
                                            <td>{loom.loomName}</td>
                                            <td>{loom.loomNumber}</td>
                                            <td>{loom.loomType}</td>
                                            <td>{loom.jacquardType}</td>
                                            <td>{loom.hooks}</td>
                                            <td>{loom.description}</td>
                                            <td>
                                                <button className="btn actionBtn" onClick={() => handleUpdateClick(loom, 'loom')}>
                                                    <img src={actionBtn} className='actionBtnIcon' alt="Update Icon" /> Update
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}

                    {activeTable === 'design' && (
                        <>
                            <h2 className='text-end'>Recent Designs</h2>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Loom Name</th>
                                        <th>Loom Number</th>
                                        <th>Design Name</th>
                                        <th>Design By</th>
                                        <th>Plan Sheet</th>
                                        <th>Design Upload</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredDesigns.map((design) => (
                                        <tr key={design.id}>
                                            <td>{design.loomName}</td>
                                            <td>{design.loomNumber}</td>
                                            <td>{design.designName}</td>
                                            <td>{design.designBy}</td>
                                            <td>
                                                {design.planSheet ? (
                                                    <img
                                                        src={design.planSheet}
                                                        alt="Plan Sheet"
                                                        className="img-thumbnail"
                                                        style={{ width: '150px', height: '150px' }}
                                                    />
                                                ) : (
                                                    <div
                                                        className="default-thumbnail"
                                                        style={{
                                                            width: '150px',
                                                            height: '150px',
                                                            border: '1px solid #ddd',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            backgroundColor: '#f5f5f5',
                                                            color: '#999',
                                                            borderRadius: '4px'
                                                        }}
                                                    >
                                                        No Plan Sheet
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                {design.designUpload ? (
                                                    <img
                                                        src={design.designUpload}
                                                        alt="Design Upload"
                                                        className="img-thumbnail"
                                                        style={{ width: '150px', height: '150px' }}
                                                    />
                                                ) : (
                                                    <div
                                                        className="default-thumbnail"
                                                        style={{
                                                            width: '150px',
                                                            height: '150px',
                                                            border: '1px solid #ddd',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            backgroundColor: '#f5f5f5',
                                                            color: '#999',
                                                        }}
                                                    >
                                                        No Design Upload
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <button className="btn actionBtn" onClick={() => handleUpdateClick(design, 'design')}>
                                                    <img src={actionBtn} className='actionBtnIcon' alt="Update Icon" /> Update
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </div>
            </div >
        </div >
    );
};

export default Weaver;
